import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";


export const Loader01 = (props) => {
  const cn = `w-[10rem] aspect-square bg-acc0 rounded-md`;
  return (
    <motion.div
      className={twMerge(cn, props.className)}
      animate={{
        scale: [1, 2, 2, 1, 1],
        rotate: [0, 0, 180, 180, 0],
        borderRadius: ["3%", "3%", "50%", "50%", "0%"],
      }}
      transition={{
        duration: 1,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 0.3,
      }}
    />
  );
};

export const Loader01c = ({
  ccn = "w-[15rem] h-[15rem] mx-auto",
  cn = "w-[5rem] h-[5rem]",
  extcn = "",
  size = "l",
}) => {
  if (size == "s") {
    ccn = "w-[2rem]";
    cn = "w-[1.2rem]";
  }
  return (
    <div className={twMerge("flex flex-col justify-center items-center", ccn)}>
      <Loader01 className={twMerge(cn, extcn)} />
    </div>
  );
};

export const BlinkingCursor = ({ speed, ...props }) => {
  const cn = `inline`;
  return (
    <motion.div
      className={twMerge(cn, props.className)}
      animate={{
        opacity: ["10%", "80%", "100%", "80%", "10%"],
        // scale: [1, 1, 1, 1, 1],
      }}
      transition={{
        duration: 0.8 * (1 / speed),
        ease: "easeInOut",
        times: [0, 0.3, 0.5, 0.7, 1],
        repeat: Infinity,
        repeatDelay: 0.2 * (1 / speed),
      }}
    >
      |
    </motion.div>
  );
};
