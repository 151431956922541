import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "./AuthWrapper.js";
import { q_bikeinfo, useStepQuery } from "../queries/queries.js";
import { useQueries } from "react-query";
import { getv, jparse, jstr, nils } from "../utils/utils.js";
import _ from "lodash";
import { set_state_ob } from "../components/input.js";

export const AccountContext = createContext();
export const useAccountContext = () => useContext(AccountContext);

const def_acc_config = {
  mode: "all",
  vaulthconfs: {},
};

export default function AccountWrapper(props) {
  const aucon = useAuthContext();
  const vault = aucon.vault;

  const acc_config_locpath = `acc_config-${vault}`;
  const [acc_config, set_acc_config] = useState(
    jparse(localStorage.getItem(acc_config_locpath), def_acc_config)
  );
  useEffect(() => {
    if (_.isEmpty(acc_config)) {
      let ob = jparse(localStorage.getItem(acc_config_locpath), def_acc_config);
      localStorage.setItem(acc_config_locpath, jstr(def_acc_config));
      set_acc_config(ob);
    } else {
      localStorage.setItem(acc_config_locpath, jstr(acc_config));
    }
  }, [acc_config]);
  const s_acc_config = (p, v) => {
    set_state_ob(acc_config, set_acc_config, p, v);
  };

  const accon = {
    vault,

    acc_config,
    set_acc_config,
    s_acc_config,
  };
  return (
    <AccountContext.Provider value={accon}>
      {props.children}
    </AccountContext.Provider>
  );
}
