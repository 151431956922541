import _ from "lodash";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso_format,
  jstr,
  nano,
  nils,
  to_time_mini,
} from "./utils.js";
import { BImg, Card, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";

import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlagCheckered,
  faStar,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { Loader01c } from "../components/anims.js";

export const get_race_from_mini = ([rid, mini]) => {
  const regex =
    /^(\d+)-([a-z\d]+)-([a-z\d]+)-(\w+)-([a-z\d]+)-\[(.*?)\]-\{([\d.]+)\}-\{([\d.]+)\}$/i;

  const match = mini.match(regex);
  if (match) {
    const cb = parseInt(match[1]);
    const c = parseInt(match[2]);
    const feetag = match[3];
    const rgate = parseInt(match[4]);
    const payout = match[5];
    let eventtags = match[6];
    eventtags = nils(eventtags) ? [] : eventtags.split(",");
    const fee = parseFloat(match[7]);
    const prize = parseFloat(match[8]);
    const prize_map = gen_prize_map(payout, prize);

    return {
      rid,
      cb: cb,
      class: c,
      feetag,
      rgate,
      payout,
      eventtags,
      fee,
      prize,
      prize_map,
      status: "open",
      mini,
    };
  } else return null;
};

export const postxt = (pos) => {
  return (
    (pos > 3 && `${pos}th`) ||
    (pos == 1 && `1st`) ||
    (pos == 2 && `2nd`) ||
    (pos == 3 && `3rd`) ||
    ""
  );
};

export const PosTag = ({ pos, className }) => {
  return (
    <Tag
      className={twMerge(
        className,
        pos <= 3 ? `bg-pos${pos} text-black` : "bg-slate-600 text-white"
      )}
    >
      {postxt(pos)}
    </Tag>
  );
};

export const BY_Star = ({ star, className }) => {
  const [bluestar, yellowstar] = useMemo(() => {
    if (star == 5) return [1, 1];
    else if (star == 3) return [0, 1];
    else if (star == 2) return [1, 0];
    else return [0, 0];
  }, [star]);

  return (
    <div className="flex flex-row justify-center items-center gap-1 px-2 my-1">
      {bluestar == 1 && (
        <FontAwesomeIcon
          icon={faStar}
          className={twMerge("text-blue-400", className)}
        />
      )}
      {yellowstar == 1 && (
        <FontAwesomeIcon
          icon={faStar}
          className={twMerge("text-yellow-400", className)}
        />
      )}
    </div>
  );
};

const payouts_ob = {
  wta: [[1, 1]],
  top2: [
    [1, 0.7],
    [2, 0.3],
  ],
  top3: [
    [1, 0.65],
    [2, 0.25],
    [3, 0.1],
  ],
  top4: [
    [1, 0.25],
    [2, 0.25],
    [3, 0.25],
    [4, 0.25],
  ],
  dblup6: [
    [1, 0.33],
    [2, 0.33],
    [3, 0.33],
  ],
  dblup12: [
    [1, 0.166],
    [2, 0.166],
    [3, 0.166],
    [4, 0.166],
    [5, 0.166],
    [6, 0.166],
  ],
  pity6: [
    [2, 0.6],
    [3, 0.6],
    [4, 0.6],
  ],
  pity12: [
    [4, 0.6],
    [3, 0.6],
    [5, 0.6],
  ],
};

const gen_prize_map = (payout, prize) => {
  let o = {};
  if (payout == "na" || nils(payout)) return {};
  let pmap = payouts_ob[payout];

  for (let [pos, frac] of pmap) {
    o[pos] = parseFloat(dec(frac * prize, 6));
  }
  return o;
};

export const get_race_rtstatus = (r, now) => {
  if (nils(r)) return [null, null, null];
  let { start_time, end_time, status } = r;
  let st = nano(start_time);
  let ed = nano(end_time);
  if (status == "cancelled") return ["cancelled", null, null];
  if (status == "open") return ["open", null, null];

  if (now > ed) return ["finished", null, null];

  let rem_st = parseInt((st - now) / 1000);
  let rem_ed = parseInt((ed - now) / 1000);
  if (_.inRange(now, st, ed) || rem_st < 20) return ["live", rem_st, rem_ed];
  if (now < st) return ["scheduled", rem_st, rem_ed];
  return [null, null, null];
};

export const feeob = {
  X: [100.0, 100, 200],
  A: [25.0, 17.5, 100],
  B: [15.0, 12.5, 17.5],
  C: [10.0, 6.5, 12.5],
  D: [5.0, 3.75, 6.5],
  E: [2.5, 1.25, 3.75],
  E75: [1.0, 0.75, 1.25],
  E50: [0.5, 0.00001, 0.75],
  F: [0.0, 0.0, 0.0],
};
export const fee_tag_txt = (fee) => {
  let a = feeob[fee][0];
  return `$${dec(a, 1)}`;

  // for (let [k, [a, b, c]] of _.entries(feeob))
  //   if (_.inRange(fee, b, c)) return `$${dec(a, 1)}`;
  // return "--";
};
export const fee_tag_cn = (fee) => {
  return "bg-green-400";
};

export const RaceHRow = ({
  rid,
  hid,
  h,
  is_vhhid = false,
  status = "open",
  race = {},
  openh = {},
  hstatstype = "cb",
}) => {
  const hstats = (
    <div className="fr-sc gap-2">
      <div className="fc-cc gap-2">
        <div className="fr-sc gap-2 font-mon resp-text--2  text-yellow-500">
          <span>Overall: </span>
          <div className="fr-cc xs:w-[2rem] lg:w-[4rem] gap-1">
            <FontAwesomeIcon icon={faTrophy} />
            <span>{dec(getv(h, `hstat.${"ov"}.win_p`) * 100, 1)}%</span>
          </div>
          <span>/</span>
          <div className="fr-cc xs:w-[1.5rem] lg:w-[3rem] gap-1">
            <FontAwesomeIcon icon={faFlagCheckered} />
            <span>{dec(getv(h, `hstat.${"ov"}.races_n`), 0)}</span>
          </div>
        </div>
        <div className="fr-sc gap-2 font-mon resp-text--2 text-green-500">
          <span>Overall Paid: </span>
          <div className="fr-cc xs:w-[2rem] lg:w-[4rem] gap-1">
            <FontAwesomeIcon icon={faTrophy} />
            <span>{dec(getv(h, `hstat.${"ov"}.paid_win_p`) * 100, 1)}%</span>
          </div>
          <span>/</span>
          <div className="fr-cc xs:w-[1.5rem] lg:w-[3rem] gap-1">
            <FontAwesomeIcon icon={faFlagCheckered} />
            <span>{dec(getv(h, `hstat.${"ov"}.paid_races_n`), 0)}</span>
          </div>
        </div>
      </div>
      <div className="fc-cc gap-2">
        <div className="fr-sc gap-2 font-mon resp-text--2  text-yellow-500">
          <span>CB: </span>
          <div className="fr-cc xs:w-[2rem] lg:w-[4rem] gap-1">
            <FontAwesomeIcon icon={faTrophy} />
            <span>{dec(getv(h, `hstat.${"cb"}.win_p`) * 100, 1)}%</span>
          </div>
          <span>/</span>
          <div className="fr-cc xs:w-[1.5rem] lg:w-[3rem] gap-1">
            <FontAwesomeIcon icon={faFlagCheckered} />
            <span>{dec(getv(h, `hstat.${"cb"}.races_n`), 0)}</span>
          </div>
        </div>
        <div className="fr-sc gap-2 font-mon resp-text--2 text-green-500">
          <span>CB Paid: </span>
          <div className="fr-cc xs:w-[2rem] lg:w-[4rem] gap-1">
            <FontAwesomeIcon icon={faTrophy} />
            <span>{dec(getv(h, `hstat.${"cb"}.paid_win_p`) * 100, 1)}%</span>
          </div>
          <span>/</span>
          <div className="fr-cc xs:w-[1.5rem] lg:w-[3rem] gap-1">
            <FontAwesomeIcon icon={faFlagCheckered} />
            <span>{dec(getv(h, `hstat.${"cb"}.paid_races_n`), 0)}</span>
          </div>
        </div>
      </div>
    </div>
  );
  const hdets = (
    <div className="fr-sc gap-2 font-mon text-acc0 resp-text--2">
      <div className="fr-cc xs:w-[1rem] lg:w-[2rem] gap-1">
        <span>F{h.fno}</span>
      </div>
      <span>/</span>
      <div className="fr-cc xs:w-[2rem] lg:w-[4rem] gap-1">
        <span>{_.capitalize(h.type)}</span>
      </div>
    </div>
  );

  const hname = (
    <div className="fr-sc gap-1">
      <p className="resp-text-1 font-digi">{h.name}</p>
      {status == "scheduled" && !nils(race) && (
        <>
          {race.bluestars.includes(hid) && (
            <FontAwesomeIcon icon={faStar} className="text-blue-400" />
          )}
          {race.yellowstars.includes(hid) && (
            <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
          )}
        </>
      )}
    </div>
  );

  const hvault = (
    <p className="resp-text--3 font-digit text-acc0">{h.vault_name}</p>
  );

  const himg = <BImg className={"w-[2rem] h-[2rem]"} hex_code={h.hex_code} />;

  const hhidrow = (
    <div className="fr-sc gap-2">
      <span className="resp-text--3">{hid}</span>
    </div>
  );

  const hentrystatus =
    status == "open" && !_.isEmpty(openh) ? (
      <>
        <div className="fr-cc gap-1">
          <div className="flex-1"></div>

          <>
            {openh.confirmed == true && (
              <span className="resp-text--1 text-green-400">
                {"Entry Confirmed!!"}
              </span>
            )}
            {openh.confirmed == false && (
              <>
                <Loader01c className="bg-orange-400" size="s" />
                <span className="resp-text--1 text-orange-400">
                  {"Awaiting..."}
                </span>
              </>
            )}
          </>
        </div>
      </>
    ) : (
      <></>
    );

  // useEffect(() => {
  //   console.log(rid, hid, openh);
  // }, [openh]);

  const is_unraced = (getv(h, `hstat.${"ov"}.races_n`) ?? 0) == 0;

  return (
    <Card
      className={twMerge(
        "resp-p-2 gap-2 bg-reg w-full",

        is_unraced ? "bg-red-700" : ""
      )}
    >
      <div className="xs:hidden lg:block ">
        <div className="fr-sc w-full gap-2">
          {himg}
          <div className="fc-ss">
            {hhidrow}
            {hname}
            {hvault}
          </div>
          <div className="flex-1 fr-cc">
            <div className="flex-1"></div>
            {hdets}
          </div>
          <div className="flex flex-col justify-end items-end gap-2">
            {hstats}
            {hentrystatus}
          </div>
        </div>
      </div>
      <div className="lg:hidden xs:block">
        <div className="fr-cc w-full">
          {hname}
          <div className="flex-1"></div>
          {hdets}
        </div>
        <div className="fr-cc w-full">
          {hvault}
          <div className="flex-1"></div>
          <div className="flex flex-col justify-end items-end gap-1">
            {hstats}
            {hentrystatus}
          </div>
        </div>
      </div>
    </Card>
  );
};
