import _ from "lodash";
import { fpost } from "./fetch.js";
import { getv, jstr } from "../utils/utils.js";
import { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { get_auth_header } from "../wrappers/AuthWrapper.js";

const usedevloc = true;

export const qiserr = (q) => {
  if (getv(q, "data.err")) return getv(q, "data.err");
  if (q.status == "error") return "server error";
  if (q.status == "loading") return false;
  return false;
};

export const iserr = (d) => {
  if (getv(d, "err") || d.status == "error") return getv(d, "data.err");
  return false;
};

export const qissuccess = (q) => {
  return getv(q, "status") == "success" && !qiserr(q);
};

export const qissuccesss = (q) => {
  return (
    getv(q, "status") == "success" &&
    getv(q, "data.status") == "success" &&
    !qiserr(q)
  );
};

export const useStepQuery = ({
  key,
  par_ar,
  q_,
  params,
  lim = 1,
  options = {},
  ...props
}) => {
  // useEffect(() => console.log("useStepQuery", par_ar), [par_ar]);

  const [enabled_n, set_enabled_n] = useState(lim);
  const qs = useQueries(
    par_ar?.map((e, idx) => {
      let ext_options = { ...options, enabled: idx < enabled_n };
      // return () => {return { test: 0 }};
      return q_(...e, ext_options);
    }),
  );
  const c = useMemo(() => {
    let ob = _.countBy(qs, (e) => e.status);
    return {
      success: ob.success ?? 0,
      loading: ob.loading ?? 0,
      error: ob.error ?? 0,
      idle: ob.idle ?? 0,
    };
  }, [qs]);

  useEffect(() => {
    set_enabled_n((parseInt(c.success / lim) + 1) * lim);
  }, [c.success]);

  const datas = useMemo(() => {
    // if (qs_count.loading !== 0) return [];
    let ob = _.chain(qs).filter({ status: "success" }).map("data").value();
    // console.log("all_horses_data", ob);
    return ob;
  }, [jstr(_.map(qs, "dataUpdatedAt")), c]);

  return {
    qs,
    datas,
    c,
    n: par_ar.length,
    done: c.success,
    tot: par_ar.length,
    loading: c.idle + c.loading > 0,
  };
};

export const btbk =
  process.env.NODE_ENV == "development" && usedevloc
    ? `http://localhost:3005`
    : `https://api.dnaracing.run`;

export const q_auth_get_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/get_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_token = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_helper`;
  return fpost(api, doc, {});
};

export const q_construct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      return fpost(api, doc);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_auconstruct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      let headers = get_auth_header();
      return fpost(api, doc, headers);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_hstats = (doc, ext = {}) => {
  let o = q_construct("q_hstats", `${btbk}/fbike/races/hstats`, doc, ext);
  return o;
};

export const q_bikesearch = (doc, ext = {}) => {
  let o = q_construct("q_bikesearch", `${btbk}/fbike/i/search`, doc, ext);
  return o;
};

export const q_helper_add_customrrace = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_helper_add_customrrace",
    `${btbk}/fbike/helper/races/add_customrrace`,
    doc,
    ext,
  );
  return o;
};

export const q_helper_quick_actions = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_helper_quick_actions",
    `${btbk}/fbike/helper/races/quick_actions`,
    doc,
    ext,
  );
  return o;
};

export const q_vaultinfo = (doc, ext = {}) => {
  let o = q_construct("q_vaultinfo", `${btbk}/fbike/vault/info`, doc, ext);
  return o;
};

export const q_get_roundspreset_s = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1000,
    refetchInterval: 2 * 60 * 1000,
    ...ext,
  };
  let o = q_auconstruct(
    "q_get_roundspreset_s",
    `${btbk}/fbike/helper/get_roundspreset_s`,
    doc,
    ext,
  );
  return o;
};
export const q_save_roundspreset = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_save_roundspreset",
    `${btbk}/fbike/helper/save_roundspreset`,
    doc,
    ext,
  );
  return o;
};
export const q_delete_roundspreset = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_delete_roundspreset",
    `${btbk}/fbike/helper/delete_roundspreset`,
    doc,
    ext,
  );
  return o;
};

export const q_helper_get_quick_actions = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_helper_get_quick_actions",
    `${btbk}/fbike/helper/races/get_quick_actions`,
    doc,
    ext,
  );
  return o;
};
