import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppContext } from "../App.js";
import {
  token_locpath,
  tokenacc_locpath,
  useMetaMaskContext,
} from "./MetaMaskWrapper.js";
import { q_auth_verify_token, qiserr } from "../queries/queries.js";
import { useQueries } from "react-query";
import { cdelay, getv, jstr, nils } from "../utils/utils.js";
import Layout from "../components/Layout.js";
import { backdropimg, metamaskimg } from "../utils/links.js";
import { Img, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import { Loader01 } from "../components/anims";

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export const get_auth_header = () => {
  let token = localStorage.getItem(token_locpath);
  let vault = localStorage.getItem(tokenacc_locpath);
  vault = vault.toLowerCase();
  return {
    "x-token": token,
    "x-vault": vault,
  };
};

export const NoAuthBackdrop = (props) => {
  return (
    <div
      style={{
        height: `92vh`,
        background: `url(${backdropimg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backdropFilter: "blur(10px)",
      }}
      className="w-[100vw] overflow-hidden"
    >
      {props.children}
    </div>
  );
};

function AuthWrapper(props) {
  const { path, history } = useAppContext();
  const mmcon = useMetaMaskContext();

  const bypass = useMemo(() => {
    if (path == "/" || path == "/login") return true;
    return false;
  }, [path]);

  const [refreshc, set_refreshc] = useState(0);
  const [account, token] = useMemo(() => {
    let account = window.ethereum.selectedAddress;
    let token = mmcon.get_token();
    // console.log("refreshc", refreshc, account, token);
    return [account, token];
  }, [refreshc]);
  const vault = account && account.toLowerCase();

  const qo_enabled = !nils(vault) && !nils(token);
  const [qo_auth] = useQueries([
    {
      queryKey: ["auth", jstr({ vault, token })],
      queryFn: () => q_auth_verify_token({ vault, token }),
      staleTime: 5 * 60 * 1e3,
      enabled: qo_enabled,
    },
  ]);
  const [auth, autherr] = useMemo(() => {
    if (!qo_enabled) return [false, "couldnt find auth details"];

    let err = qiserr(qo_auth);
    if (err) {
      // history("/login");
      localStorage.removeItem(token_locpath);
      localStorage.removeItem(tokenacc_locpath);
      return [false, err];
    }

    let auth = getv(qo_auth, "data.result.auth");
    if (auth) return [true, null];
    else return [false, `couldn't authenticate`];
  }, [qo_auth.dataUpdatedAt]);

  useEffect(() => {
    if (qo_auth.isLoading) return;
    if (auth == false && bypass == false) {
      // history("/login");
    }
  }, [auth, autherr, qo_auth.dataUpdatedAt]);

  const auth_refresh = async () => {
    set_refreshc(refreshc + 1);
    await cdelay(500);
    await qo_auth.refetch();
  };

  const logout = async () => {
    localStorage.removeItem(token_locpath);
    localStorage.removeItem(tokenacc_locpath);
    await auth_refresh();
  };

  const aucon = {
    auth,
    account,
    vault,
    token,
    loading: qo_auth.isLoading,
    auth_refresh,
    logout,

    qo_auth,
    autherr,
  };

  return (
    <AuthContext.Provider value={aucon}>
      {bypass == true ? (
        props.children
      ) : (
        <>
          {auth == true ? (
            props.children
          ) : (
            <>
              <Layout>
                <NoAuthBackdrop />
              </Layout>
            </>
          )}
        </>
      )}
    </AuthContext.Provider>
  );
}

export const NavLogin = () => {
  const aucon = useAuthContext();
  const mmcon = useMetaMaskContext();

  const [logloading, set_logloading] = useState(false);
  const [logerr, set_logerr] = useState(null);
  const start_login = async () => {
    set_logloading(true);
    try {
      let account = await mmcon.request_metamask_login();
      if (nils(account)) throw new Error("metamask window was closed abruptly");

      let jwtacc = localStorage.getItem(tokenacc_locpath);
      if (jwtacc == account) {
      } else {
        await mmcon.gen_token();
      }
      await cdelay(1000);
      await aucon.auth_refresh();
      await cdelay(2000);
      set_logloading(false);
    } catch (err) {
      set_logerr(err.message);
    }
  };

  return (
    <div className="fr-cc">
      {(logerr || aucon.autherr) && (
        <p className="text-center font-mon text-red-400 text-[0.8rem] px-4">
          {logerr || aucon.autherr}
        </p>
      )}

      <Tag
        onClick={() => {
          if (logloading || aucon.loading) return;
          start_login();
        }}
        className={twMerge(
          logloading || aucon.loading
            ? "bg-acc0/40 text-white "
            : "bg-acc0 px-2 fr-sc gap-2 font-digi text-black mx-4"
        )}
      >
        {logloading || aucon.loading ? (
          <span className="text-[0.8rem]">loading...</span>
        ) : (
          <>
            <Img className={"w-[2rem] h-[2rem]"} img={metamaskimg} />
            <span>Login</span>
          </>
        )}
      </Tag>
    </div>
  );
};

export default AuthWrapper;
