import React from "react";

function NoColor() {
  return (
    <div>
      <div className="h-0 hide bg-acc0 shadow shadow-acc0"></div>
      <div className="h-0 hide bg-c1 text-c1 border-c1 shadow shadow-c1"></div>
      <div className="h-0 hide bg-c2 text-c2 border-c2 shadow shadow-c2"></div>
      <div className="h-0 hide bg-c3 text-c3 border-c3 shadow shadow-c3"></div>
      <div className="h-0 hide bg-c4 text-c4 border-c4 shadow shadow-c4"></div>
      <div className="h-0 hide bg-c5 text-c5 border-c5 shadow shadow-c5"></div>
      <div className="h-0 hide bg-c70 text-c70 border-c70 shadow shadow-c70"></div>
      <div className="h-0 hide bg-c72 text-c72 border-c72 shadow shadow-c72"></div>

      <div className="h-0 hide bg-40 text-40 border-40 shadow shadow-40"></div>
      <div className="h-0 hide bg-c30 text-c30 border-c30 shadow shadow-c30"></div>
      <div className="h-0 hide bg-c40 text-c40 border-c40 shadow shadow-c40"></div>
      <div className="h-0 hide bg-c50 text-c50 border-c50 shadow shadow-c50"></div>
      <div className="h-0 hide bg-c60 text-c60 border-c60 shadow shadow-c60"></div>
      <div className="h-0 hide bg-c70 text-c70 border-c70 shadow shadow-c70"></div>
      <div className="h-0 hide bg-c80 text-c80 border-c80 shadow shadow-c80"></div>
      <div className="h-0 hide bg-c90 text-c90 border-c90 shadow shadow-c90"></div>
    </div>
  );
}

export default NoColor;
