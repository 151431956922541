import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import qs from "query-string";
import { createContext } from "react";
import { MetaMaskWrapper } from "./wrappers/MetaMaskWrapper.js";
import AuthWrapper from "./wrappers/AuthWrapper.js";
import HomePage from "./views/HomePage.js";
import Layout from "./components/Layout.js";
import NoColor from "./wrappers/NoColor.js";
import AccountWrapper from "./wrappers/AccountWrapper.js";
import CustomRace from "./views/CustomRace.js";

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const NowContext = createContext({ now: null });
export const useNowContext = () => useContext(NowContext);

function App() {
  const history = useNavigate();
  const location = useLocation();
  const psearch = qs.parse(location.search);

  const upd_psearch = (upd) => {
    let se = qs.stringify({ ...psearch, ...upd }, { arrayFormat: "bracket" });
    history(`${location.pathname}?${se}`);
  };

  const [now, set_now] = useState(Date.now());
  useEffect(() => {
    setTimeout(() => set_now(Date.now()), 200);
  }, [now]);

  const appcon = {
    history,
    location,
    path: location.pathname,
    psearch,
    upd_psearch,
  };

  return (
    <div className="App bg-dark text-white">
      <NowContext.Provider value={{ now }}>
        <AppContext.Provider value={appcon}>
          <MetaMaskWrapper>
            <AuthWrapper>
              <AccountWrapper>
                <Layout>
                  <Routes>
                    <Route path="/customrace" exact element={<CustomRace />} />
                    <Route path="/" exact element={<HomePage />} />
                  </Routes>
                </Layout>
              </AccountWrapper>
            </AuthWrapper>
          </MetaMaskWrapper>
          <NoColor />
        </AppContext.Provider>
      </NowContext.Provider>
    </div>
  );
}

export default App;
