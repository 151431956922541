import React, { useEffect } from "react";
import YouTube from "react-youtube";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { useAppContext } from "../App.js";

function HomePage() {
  const { path, history } = useAppContext();
  const aucon = useAuthContext();
  useEffect(() => {
    if (aucon.auth == true) {
      history("/races");
    }
  }, [aucon]);

  const videoId = "SqSuRdkglxM";
  return (
    <div>
      
    </div>
  );
}

export default HomePage;
