import _ from "lodash";
import { getv, nils } from "./utils.js";

export const gen_valob_from_filters = (filters) => {
  return _.chain(filters)
    .entries()
    .map(([k, v]) => {
      return [k, v?.vals];
    })
    .fromPairs()
    .value();
};

export const gen_filters_from_valob = (filters, valob) => {
  return _.chain(filters)
    .entries()
    .map(([k, v]) => {
      v.vals = valob[k];
      return [k, v];
    })
    .fromPairs()
    .value();
};

export const gen_filters_cleared = (filters) => {
  return _.chain(filters)
    .entries()
    .map(([k, v]) => {
      v = _.cloneDeep(v);
      if (["range"].includes(v.type)) v.vals = { mi: null, mx: null };
      else if (["options", "options-only-ar"].includes(v.type)) v.vals = [];
      else if (["only", "options-only"].includes(v.type)) v.vals = null;
      else v.vals = null;
      return [k, v];
    })
    .fromPairs()
    .value();
};

export const gen_filter_state_ob = (
  filterob = {},
  keys = [],
  locfiltvalob = {}
) => {
  let o = {};
  if (nils(keys)) return o;
  for (let krow of keys) {
    if (nils(krow)) continue;
    let [k, ex = {}] = krow;
    if (nils(filterob[k])) {
      // console.log(k, "filtob key not found");
    }
    let v = _.cloneDeep(filterob[k]);
    let vals = locfiltvalob[k];
    v = { ...v, ...ex };
    if (!nils(vals)) {
      v.vals = vals;
    }
    o[k] = v;
    if (ex.useby === true) {
      o[`${k}_by`] = {
        type: "mimx",
        vals: getv(locfiltvalob, `${k}_by`),
      };
    }
  }
  return o;
};

export const filt_ar_using_filters = ({ races, filters }) => {
  try {
    const filt = _.filter(races, (h) => {
      let p = 0;
      // if (p) console.log(bike);
      const { hid } = h;
      for (let [fkey, fob] of _.entries(filters)) {
        let { vals: f, path: k, cfilter = true } = fob;
        if (cfilter == false) continue;

        if (_.isEmpty(f)) continue;
        if (_.isFunction(k)) {
          // console.log('fn', k)
          let resp = k(h, f, filters);
          if (resp == false) return false;
          else continue;
        } else if (_.isArray(f)) {
          let val = getv(h, k);
          if (_.isEmpty(f));
          else if (!f.includes(val)) {
            if (p) console.log(hid, "na array", k, val);
            return false;
          }
        } else {
          let val = getv(h, k);
          let { mi = undefined, mx = undefined } = f;
          val = parseFloat(val);
          if (p) console.log(k, { mi, mx });
          if (mi == undefined && mx == undefined);
          else if (mx !== undefined && val > mx) {
            if (p) console.log(hid, "na mx", k, val);
            return false;
          } else if (mi !== undefined && val < mi) {
            if (p) console.log(hid, "na mi", k, val);
            return false;
          } else if (!_.inRange(val, mi || 1e-5, (mx || 1e5) + 1e-8)) {
            if (p) console.log(hid, "na range", k, val);
            return false;
          }
        }
      }
      if (p) console.log("###", hid, "valid");
      return true;
    });
    return filt;
  } catch (err) {
    console.log(err);
    return races;
  }
};
