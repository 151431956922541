import { twMerge } from "tailwind-merge";
import { getv } from "./utils.js";
import _ from "lodash";

const tclassmap = {
  1: [1, `Class 1`, "C1", "bg-c1"],
  2: [2, `Class 2`, "C2", "bg-c2"],
  3: [3, `Class 3`, "C3", "bg-c3"],
  4: [4, `Class 4`, "C4", "bg-c4"],
  5: [5, `Class 5`, "C5", "bg-c5"],
  72: [72, `Special Class`, "Special", "bg-c72"],

  30: [40, `Training`, "Tr", "bg-c30"],
  40: [40, `Arcade`, "Arcade", "bg-c40"],

  50: [50, "Claiming", "CLM", "bg-c50"],
  60: [60, "Satellites", "SAT", "bg-c60"],
  70: [70, `Tourney`, "Tour", "bg-c70"],
  80: [80, "Open Paid", "O$$", "bg-c80"],
  90: [90, "Open Frees", "FREE", "bg-c90 "],
};
export const classes = _.keys(tclassmap);
export const class_text = (code, format = "t") => {
  format =
    (format == "t" && 2) ||
    (format == "c" && 0) ||
    (format == "T" && 1) ||
    null;
  return getv(tclassmap, `${code}.${format}`);
};
export const class_cn = (code, cn) => {
  return twMerge(getv(tclassmap, `${code}.${3}`), cn);
};
const payout_cnob = {
  na: ["na", "bg-dark"],
  wta: ["WTA", "bg-purple-500/40"],
  "1v1": ["1 v 1", "bg-purple-500/40"],
  top2: ["TOP2", "bg-purple-500/40"],
  top3: ["TOP3", "bg-purple-500/40"],
  top4: ["TOP4", "bg-purple-500/40"],
  dblup: ["DBL UP", "bg-teal-500/40"],
  dblup6: ["DBL UP", "bg-teal-500/40"],
  dblup12: ["DBL UP", "bg-teal-500/40"],
  pity: ["PITY", "bg-pink-600/40"],
  pitylow: ["PITY-LOW", "bg-pink-600/40"],
  pity6: ["PITY", "bg-pink-600/40"],
  pity12: ["PITY", "bg-pink-600/40"],
  spin_n_go: ["SPIN N GO", "bg-pink-500/40"],
  lowspin: ["LOW SPIN", "bg-pink-500/40"],
  pityspin: ["PITY SPIN", "bg-pink-500/40"],
  hybrid: ["HYBRID", "bg-orange-400/40"],
  rounds: ["rounds", "bg-transparent"],
};

export const get_payout_cn = (p) => {
  return getv(payout_cnob, `${p}.1`) || "bg-black";
};

export const get_payout_txt = (p) => {
  return getv(payout_cnob, `${p}.0`) || _.upperCase(p);
};

export const cbs = [10, 12, 14, 16, 18, 20, 22];
export const cb_cn = (code, cn) => {
  return twMerge("bg-acc0 bg-opacity-30", cn);
};
export const cb_txt = (cb) => {
  return `CB${cb}00`;
};

export const tablecn = {
  td: "border-b border-slate-600 px-2 md:pl-6 py-1 text-left min-w-[5rem]",
  th: "border-b border-acc0/40 px-2 md:pl-6 py-1 text-center min-w-[5rem] text-acc0 font-bold",
  tr: "",
  table: "border-b border-slate-500 min-w-[20rem] my-2 mx-auto",
};

export const pos_txt = (p) => {
  return (
    (p == 1 && `1st`) || (p == 2 && `2nd`) || (p == 3 && `3rd`) || `${p}th`
  );
};

export const pos_cn = (p) => {
  return p <= 3 ? `bg-pos${p} text-black` : "bg-slate-800";
};
